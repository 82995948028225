import React from 'react'
import { Link } from 'react-router-dom'

function AboutSection() {
    return (
        <>
            {/* <!-- ======= About Section ======= --> */}
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">

                    <div className="row align-items-center">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            <img src="assets/img/who-we-are.jpg" className="img-fluid" alt="who-we-are" loading='lazy' />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                            <h2>Who We Are?</h2>
                            <p className="fst-italic">
                                “City Translink offers a transparent assessment ecosystem that employs a hybrid methodology, 
                                leveraging a network of qualified assessors, localized test centers, practical/viva-voice evaluations, 
                                industry-validated question banks, and a versatile online/offline technology platform. 
                                Our presence extends across India.”
                            </p>
                            <p>
                                Embracing the legacy of Peter Drucker and placing our trust in individuals, we stand as a prominent force
                                in the recruitment domain. Our mission is to narrow the Skill Gap within the nation, offering a
                                comprehensive solution for both industries and job seekers. We deliver a range of services, including
                                assessments, apprenticeship opportunities, and hiring support, extending our commitment to stakeholders
                                nationwide.
                            </p>
                            <Link to="about" className="learn-more-btn">Learn More</Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End About Section --> */}
        </>
    )
}

export default AboutSection
