import React from 'react'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function Individuals() {
    return (
        <>
            <Helmet>    
                <title>For Individuals || City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount />
            <main id="main">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h1>For Individuals</h1>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}

                <section className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            {/* <h5 className='fw-bold mb-2'>End To End Hiring Solution</h5> */}
                            <p>
                                In today's competitive landscape, standing out from the crowd is crucial.
                                A highly effective strategy for achieving this is acquiring additional skills and obtaining certifications in those areas.
                                Your skill set is the key to securing a job or advancing your career within your field.
                            </p>
                            <p>
                                City Translink offers a variety of certification options to candidates.
                                Candidates have the flexibility to choose their preferred certification agency from the list below.
                                The certifications are provided by any of the agencies mentioned in the following list.
                            </p>
                            <ul>
                                <li>University certification</li>
                                <li>Sector skill council certification</li>
                                <li>Industry body certification</li>
                                <li>CTAT Score</li>
                            </ul>
                        </div>
                        <div className='col-md-6 text-center'>
                            <img src='assets/img/Individuals-image.jpg' className='img-fluid border-radius shadow' alt='Individuals' loading='lazy' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3'></div>
                        <div className='col-md-3'></div>
                        <div className='col-md-3'></div>
                        <div className='col-md-3'></div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Individuals
