import React from 'react'
import AboutSection from './Components/AboutSection'
import Counts from './Components/Counts'
import WhyUs from './Components/Why-Us'
import Events from './Components/Events'
import Apprenticeship from './Components/Apprenticeship'
// import EATSection from './Components/EATSection'
import Certification from './Components/Certification'
import Client from './Components/Client'
import MainHero from './Components/MainHero'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function Home() {
    return (
        <>
            <Helmet>
                <title>|| City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount />
            <MainHero />
            <main id="main">
                <AboutSection />
                <Counts />
                <WhyUs />
                <Events />
                <Apprenticeship />
                {/* <EATSection /> */}
                <Certification />
                <Client />
            </main>
        </>
    )
}

export default Home
