import React, { useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Modal } from 'react-bootstrap';
import Gallery from 'react-image-gallery';
import ScrollToTopOnMount from './ScrollToTopOnMount';
import { Helmet } from 'react-helmet';

const images = [
  {
    id: 1,
    original: 'assets/img/events-1.jpg',
    thumbnail: 'assets/img/events-1.jpg',
    description: 'Image 1',
  },
  {
    id: 2,
    original: 'assets/img/events-2.jpg',
    thumbnail: 'assets/img/events-2.jpg',
    description: 'Image 2',
  },
  {
    id: 3,
    original: 'assets/img/events-1.jpg',
    thumbnail: 'assets/img/events-1.jpg',
    description: 'Image 3',
  },
  {
    id: 4,
    original: 'assets/img/events-2.jpg',
    thumbnail: 'assets/img/events-2.jpg',
    description: 'Image 4',
  },
];

const MyGallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const openImage = (index) => {
    setSelectedIndex(index);
    setShowModal(true);
  };

  const closeImage = () => {
    setSelectedIndex(0);
    setShowModal(false);
  };

  return (
    <>
      <Helmet>
        <title>Our Gallery || City Translink ||</title>
      </Helmet>
      <ScrollToTopOnMount />
      <main id="main">
        {/* Breadcrumbs */}
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h1>Our Gallery</h1>
          </div>
        </div>
        {/* End Breadcrumbs */}

        <div className="container mt-5">
          <div className="row">
            {images.map((image, index) => (
              <div key={index} className="col-md-4 mb-4">
                <img
                  src={image.thumbnail}
                  alt={image.description}
                  className="img-fluid"
                  loading='lazy' 
                  style={{ cursor: 'pointer' }}
                  onClick={() => openImage(index)}
                />
                <p className="mt-2">{image.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Modal */}
        {showModal && (
          <Modal show={showModal} onHide={closeImage} centered size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Gallery</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Gallery
                items={images}
                startIndex={selectedIndex}
                showThumbnails={false}
                showFullscreenButton={true}
                showPlayButton={true}
              />
            </Modal.Body>
          </Modal>
        )}
      </main>
    </>
  );
};

export default MyGallery;
