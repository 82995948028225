import React from 'react'
import { Link } from 'react-router-dom'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function Blog() {
    return (
        <>
            <Helmet>    
                <title>Blogs || City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount />
            <main id="main" data-aos="fade-in">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs">
                    <div className="container">
                        <h1>Our Blog</h1>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}

                <section id="courses" className="courses">
                    <div className="container" data-aos="fade-up">
                        <div className="row" data-aos="zoom-in" data-aos-delay="100">
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <div className="course-item">
                                    <img src="assets/img/course-1.jpg" className="img-fluid" alt="course" loading='lazy' />
                                    <div className="course-content">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h4>Web Development</h4>
                                            <p className="price">$169</p>
                                        </div>
                                        <h3><Link to="#">Website Design</Link></h3>
                                        <p>Et architecto provident deleniti facere repellat nobis iste. Id facere quia quae dolores dolorem tempore.</p>
                                        <div className="trainer d-flex justify-content-between align-items-center">
                                            <div className="trainer-profile d-flex align-items-center">
                                                <img src="assets/img/trainers/trainer-1.jpg" className="img-fluid" alt="trainer" loading='lazy' />
                                                <span>Antonio</span>
                                            </div>
                                            <div className="trainer-rank d-flex align-items-center">
                                                <i className="bx bx-user"></i>&nbsp;50
                                                &nbsp;&nbsp;
                                                <i className="bx bx-heart"></i>&nbsp;65
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                                <div className="course-item">
                                    <img src="assets/img/course-2.jpg" className="img-fluid" alt="course" loading='lazy'/>
                                    <div className="course-content">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h4>Marketing</h4>
                                            <p className="price">$250</p>
                                        </div>
                                        <h3><Link to="#">Search Engine Optimization</Link></h3>
                                        <p>Et architecto provident deleniti facere repellat nobis iste. Id facere quia quae dolores dolorem tempore.</p>
                                        <div className="trainer d-flex justify-content-between align-items-center">
                                            <div className="trainer-profile d-flex align-items-center">
                                                <img src="assets/img/trainers/trainer-2.jpg" className="img-fluid" alt="tariner" loading='lazy' />
                                                <span>Lana</span>
                                            </div>
                                            <div className="trainer-rank d-flex align-items-center">
                                                <i className="bx bx-user"></i>&nbsp;35
                                                &nbsp;&nbsp;
                                                <i className="bx bx-heart"></i>&nbsp;42
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                                <div className="course-item">
                                    <img src="assets/img/course-3.jpg" className="img-fluid" alt="course" loading='lazy' />
                                    <div className="course-content">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h4>Content</h4>
                                            <p className="price">$180</p>
                                        </div>
                                        <h3><Link to="#">Copywriting</Link></h3>
                                        <p>Et architecto provident deleniti facere repellat nobis iste. Id facere quia quae dolores dolorem tempore.</p>
                                        <div className="trainer d-flex justify-content-between align-items-center">
                                            <div className="trainer-profile d-flex align-items-center">
                                                <img src="assets/img/trainers/trainer-3.jpg" className="img-fluid" alt="trainer" loading='lazy' />
                                                <span>Brandon</span>
                                            </div>
                                            <div className="trainer-rank d-flex align-items-center">
                                                <i className="bx bx-user"></i>&nbsp;20
                                                &nbsp;&nbsp;
                                                <i className="bx bx-heart"></i>&nbsp;85
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Blog
