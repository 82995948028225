import React from 'react'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function Schools() {
    return (
        <>
            <Helmet>
                <title>For Schools || City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount />
            <main id="main">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h1>For Schools</h1>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}

                <section className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <h5 className='fw-bold mb-2'>Career Guidance for students</h5>
                            <p>Choosing a career, especially for students, is characterized by a multitude of uncertainties and is tied to various parameters, including interests, personality, values, and importance.</p>
                            <ul className='mb-5'>
                                <li>CTAT offers a career assessment tool designed to capture these factors, aiding students in selecting a specific career path.</li>
                                <li>A team of experienced psychologists and counselors interprets and shares the results of these tests with the students.</li>
                            </ul>
                            <h5 className='fw-bold mb-2'>Psychometric assessment for school staff</h5>
                            <p>
                                Given that children spend a significant portion of their time in school, ensuring their safety and security is paramount.
                                The term 'safety' encompasses physical well-being, mental and emotional health, as well as protection against child abuse.
                                Staff members, including teachers and non-teaching personnel such as peons, bus drivers, and administrative staff, play a
                                crucial role in safeguarding the well-being of students within the school environment. City Translink takes the initiative to
                                design, conduct, and analyze psychometric evaluations for all school staff, ensuring that children can study in a secure and protective atmosphere.
                            </p>
                        </div>
                        <div className='col-md-6'>
                            <img src='assets/img/school.jpg' className='img-fluid border-radius shadow' alt='school' loading='lazy'  />
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Schools
