import React from 'react'
import { Link } from 'react-router-dom'

function WhyUs() {
    return (
        <>
            {/* <!-- ======= Why Us Section ======= --> */}
            <section id="why-us" className="why-us">
                <div className="container" data-aos="fade-up">

                    <div className="row">
                        <div className="col-lg-4 d-flex align-items-stretch">
                            <div className="content">
                                <h3>Why Choose City Translink?</h3>
                                <p>
                                    We guarantee the enlistment of a suitable count of certified assessors with
                                    Training of Assessor (TOA) accreditation across various regions and sectors.
                                    These assessors are also entrusted with ensuring the punctual receipt of login credentials.
                                    Our assessments are underpinned by research, and our cloud platform operates with efficiency.
                                </p>
                                <div className="text-center">
                                    <Link to="about" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-boxes d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-xl-4 d-flex align-items-stretch">
                                        <div className="icon-box mt-4 mt-xl-0">
                                            <i className="bx bx-receipt"></i>
                                            <h4>Expert & Professional</h4>
                                            <p>Seasoned and skilled professionals with expert-level proficiency in their respective fields.</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 d-flex align-items-stretch">
                                        <div className="icon-box mt-4 mt-xl-0">
                                            <i className="bx bx-cube-alt"></i>
                                            <h4>Online Exam</h4>
                                            <p>Conducting assessments and evaluations through a web-based platform accessible remotely for seamless online exams.</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 d-flex align-items-stretch">
                                        <div className="icon-box mt-4 mt-xl-0">
                                            <i className="bx bx-images"></i>
                                            <h4>Hiring</h4>
                                            <p>Efficient recruitment processes to acquire top talent, ensuring a strategic match between candidates and organizational needs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* <!-- End Why Us Section --> */}
        </>
    )
}

export default WhyUs
