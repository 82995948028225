import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const BackToTop = () => {
  useEffect(() => {
    const handleScroll = () => {
      const backToTop = document.querySelector('.back-to-top');

      if (backToTop) {
        if (window.scrollY > 100) {
          backToTop.classList.add('active');
        } else {
          backToTop.classList.remove('active');
        }
      }
    };

    window.addEventListener('load', handleScroll);
    window.addEventListener('scroll', handleScroll);

   return () => {
      window.removeEventListener('load', handleScroll);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Link to="#" className="back-to-top d-flex align-items-center justify-content-center" onClick={scrollToTop}>
        <i className="bi bi-arrow-up-short"></i>
      </Link>
    </>
  );
};

export default BackToTop;