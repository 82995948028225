import React from 'react'
import { Link } from 'react-router-dom'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function Assessments() {
    return (
        <>
            <Helmet>
                <title>Type of Assessments || City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount/>
            <main id="main">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h1>Type of Assessments</h1>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}

                <section className='container'>
                    <div className='row align-items-center mtb-80'>
                        <div className='col-md-6'>
                            <img src='assets/img/Online-Assessment.jpg' className='img-fluid' alt='Assessment' loading='lazy' />
                        </div>
                        <div className='col-md-6 margin_left shadow py-5'>
                            <h4 className='fw-bold mb-4'>Online Assessment Solutions</h4>
                            <p>
                                This solution offers an online assessment platform with multiple-choice questions, suitable for conducting university exams.
                                We have successfully administered online exams for over 10,000 students undertaking DCA/PGDCA level courses for various central Indian universities.
                                Results and reports can be customized and delivered based on your specific requirements.
                            </p>
                            <Link className='get-started-btn m-0' to='' >Know More</Link>
                        </div>
                    </div>

                    <div className='row align-items-center mtb-80'>
                        <div className='col-md-6 margin_right shadow py-5'>
                            <h4 className='fw-bold mb-4'>On Screen Marking Solutions</h4>
                            <p>
                                This solution seamlessly incorporates the traditional pen-and-paper free-response examination system with on-screen marking capabilities,
                                allowing professors or teachers to use tablets or laptops. This integration reduces the time required for result finalization,
                                cuts down on costs, and enhances reliability and convenience in the administration process.
                            </p>
                            <Link className='get-started-btn m-0' to='' >Know More</Link>
                        </div>
                        <div className='col-md-6'>
                            <img src='assets/img/Screen-Marking.jpg' className='img-fluid' alt='Mobile' loading='lazy' />
                        </div>
                    </div>

                    <div className='row align-items-center mtb-80'>
                        <div className='col-md-6'>
                            <img src='assets/img/Mobile-Based.jpg' className='img-fluid' alt='Mobile' loading='lazy' />
                        </div>
                        <div className='col-md-6 margin_left shadow py-5'>
                            <h4 className='fw-bold mb-4'>Mobile Based Viva</h4>
                            <p>
                                In regions with limited or no internet connectivity, we offer a solution for viva assessments through
                                mobile networks or telephones. In this system, candidates receive calls on their mobile devices from an automated system.
                                All audio interactions are recorded and stored for future reference.
                            </p>
                            <Link className='get-started-btn m-0' to='#' >Know More</Link>
                        </div>
                    </div>

                    <div className='row align-items-center mtb-80'>
                        <div className='col-md-6 margin_right shadow py-5'>
                            <h4 className='fw-bold mb-4'>Tablet Based</h4>
                            <p>
                                To facilitate examinations in remote or client locations such as retail stores or work sites,
                                we employ tablets for both online and offline assessments. Tablets are utilized for both written
                                exams and vivas, with results either uploaded to the server in real-time or synchronized at a later time,
                                depending on whether assessments are conducted online or offline.
                            </p>
                            <Link className='get-started-btn m-0' to='' >Know More</Link>
                        </div>
                        <div className='col-md-6'>
                            <img src='assets/img/Tablet-Based.jpg' className='img-fluid' alt='Tablet' loading='lazy' />
                        </div>
                    </div>

                    <div className='row align-items-center mtb-80'>
                        <div className='col-md-6'>
                            <img src='assets/img/Centre-Based.jpg' className='img-fluid' alt='Centre' loading='lazy' />
                        </div>
                        <div className='col-md-6 margin_left shadow py-5'>
                            <h4 className='fw-bold mb-4'>Centre Based Exam</h4>
                            <p>
                                For clients requiring assessments for their field staff, we offer a solution that involves
                                conducting tests at designated centers equipped with computers, internet access, printers,
                                and other necessary facilities. We have established partnerships with over 1000 centers situated nationwide.
                            </p>
                            <Link className='get-started-btn m-0' to='#' >Know More</Link>
                        </div>
                    </div>

                    <div className='row align-items-center mtb-80'>
                        <div className='col-md-12 shadow p-5'>
                            <h4 className='fw-bold mb-4'>Psychometric And Cognitive Skills Evaluation</h4>
                            <p>
                                City Translink's psychometric assessment delivers a standardized and scientifically derived CTAT Psychometric score,
                                measuring individuals' mental capabilities and behavioral styles.
                            </p>
                            <p>
                                Organizations seek employees with robust logical reasoning, strong memory,
                                and excellent processing speed for quick and focused task execution.
                            </p>
                            <p>
                                The CTAT cognitive score provides a comprehensive insight into an employee's cognitive ability,
                                showcasing a robust correlation with their performance within the organization. With a substantial
                                number of tests already conducted, candidates can be effectively benchmarked,
                                allowing for tailored interventions to optimize employee potential.
                            </p>
                            <p>
                                Research supports the credibility of cognitive aptitude tests like CTAT, recognizing them as reliable 
                                tools for fair and objective assessments, ensuring more accurate and precise predictions of job success.
                            </p>
                            <Link className='get-started-btn m-0' to='#' >Know More</Link>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Assessments
