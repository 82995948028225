import React from 'react'
import { Link } from 'react-router-dom'
import BackToTop from './BackToTop'

function Footer() {
    return (
        <>
            {/* <!-- ======= Footer ======= --> */}
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h3 className="footer_logo"><Link to="/"><img src="./assets/img/city-logo-transparent.png"
                                    alt="logo-transparent" className="img-fluid" loading='lazy' /></Link></h3>
                                <p className="mb-3">
                                    City Translink offers a transparent assessment ecosystem that employs a hybrid methodology,
                                    leveraging a network of qualified assessors, localized test centers, practical/viva-voice evaluations,
                                    industry-validated question banks, and a versatile online/offline technology platform.
                                </p>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/about">About us</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/gallery">Gallery</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/videos">Videos</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/events">Events</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/blog">Blog</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/universities">For Universities</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/schools">For Schools</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/corporates">For Corporates</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/skill-council">For Sector Skill Council</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/individuals">For Individuals</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-newsletter">
                                <h4>Our Address</h4>
                                <p>
                                405, Vashi Infotech Park, Plot No. 16,  Sector 30A, Vashi, Navi Mumbai- 400703, Maharashtra, India.
                                </p>
                                <p>
                                    <strong>Phone:</strong> +91 9324121410<br />
                                    <strong>Telephone:</strong> 22-40220707<br />
                                    <strong>Email:</strong> info@citytranslink.com
                                </p>
                                <div className="social-links text-md-right pt-3 pt-md-0">
                                    <Link to="#" className="twitter"><i className="bx bxl-twitter"></i></Link>
                                    <Link to="#" className="facebook"><i className="bx bxl-facebook"></i></Link>
                                    <Link to="#" className="instagram"><i className="bx bxl-instagram"></i></Link>
                                    <Link to="#" className="linkedin"><i className="bx bxl-linkedin"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container d-md-flex py-3">
                    <div className="me-md-auto text-center text-md-start">
                        <div className="copyright">
                            &copy; Copyright 2023 <strong><span>City Translink</span></strong>. All Rights Reserved
                        </div>
                    </div>
                    <div className="text-center text-md-right pt-3 pt-md-0">
                        <Link to="terms-conditions" className="ms-3">Terms of service</Link>
                        <Link to="privacy-policy" className="ms-3">Privacy policy</Link>
                    </div>
                </div>
            </footer >
            {/* <!-- End Footer --> */}
            <BackToTop />
        </>
    )
}

export default Footer
