import React from 'react'
import { Link } from 'react-router-dom'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function EventPage() {
    return (
        <>
            <Helmet>
                <title>Event || City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount/>
            <main id="main">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h1>Events</h1>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}

                {/* <!-- ======= Events Section ======= --> */}
                <section id="events" className="events">
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-6 d-flex align-items-stretch">
                                <div className="card">
                                    <div className="card-img">
                                        <img src="assets/img/events-1.jpg" alt="event-1" loading='lazy' />
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title"><Link to="../event-details">Introduction to webdesign</Link></h5>
                                        <p className="fst-italic text-center">Sunday, September 26th at 7:00 pm</p>
                                        <p className="card-text">Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex align-items-stretch">
                                <div className="card">
                                    <div className="card-img">
                                        <img src="assets/img/events-2.jpg" alt="event-2" loading='lazy' />
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title"><Link to="../event-details">Marketing Strategies</Link></h5>
                                        <p className="fst-italic text-center">Sunday, November 15th at 7:00 pm</p>
                                        <p className="card-text">Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End Events Section --> */}
            </main>
        </>
    )
}

export default EventPage