import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  // const [eventsDropdownOpen, setEventsDropdownOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    const closebtn = document.getElementById('closebtn');
    if (closebtn) {
      if (!mobileMenuOpen) {
        closebtn.classList.add('bi-x');
      } else {
        closebtn.classList.remove('bi-x');
      }
    }
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    setServicesDropdownOpen(false);
    // setEventsDropdownOpen(false);
    const closebtn = document.getElementById('closebtn');
    if (closebtn) {
      closebtn.classList.remove('bi-x');
    }
  };

  const toggleServicesDropdown = () => {
    setServicesDropdownOpen(!servicesDropdownOpen);
  };

  // const toggleEventsDropdown = () => {
  //   setEventsDropdownOpen(!eventsDropdownOpen);
  // };

  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" className={`fixed-top ${mobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        <div className="container d-flex align-items-center">
          <Link to="/" className="logo me-auto">
            <img src="./assets/img/CITY-Logo-New.PNG" alt="logo" className="img-fluid rounded" loading='lazy' />
          </Link>
          <nav id="navbar" className={`navbar order-last order-lg-0 ${mobileMenuOpen ? 'navbar-mobile' : ''}`}>
            <ul>
              <li><Link className={location.pathname === '/' ? 'active' : ''} to="/" onClick={closeMobileMenu}>Home</Link></li>
              <li><Link className={location.pathname === '/about' ? 'active' : ''} to="/about" onClick={closeMobileMenu}>About</Link></li>

              {/* Services Dropdown */}
              <li className={`dropdown ${servicesDropdownOpen ? 'active' : ''}`} onClick={toggleServicesDropdown}>
                <Link to="#" className={servicesDropdownOpen || location.pathname.startsWith('/universities') ? 'active' : ''}>
                  <span>Services</span> <i className="bi bi-chevron-down"></i>
                </Link>
                <ul className={`dropdown-ul ${servicesDropdownOpen ? 'dropdown-active' : ''}`}>
                  <li><Link className={location.pathname === '/universities' ? 'active' : ''} to="/universities" onClick={closeMobileMenu}>For Universities</Link></li>
                  <li><Link className={location.pathname === '/schools' ? 'active' : ''} to="/schools" onClick={closeMobileMenu}>For Schools</Link></li>
                  <li><Link className={location.pathname === '/corporates' ? 'active' : ''} to="/corporates" onClick={closeMobileMenu}>For Corporates</Link></li>
                  <li><Link className={location.pathname === '/skill-council' ? 'active' : ''} to="/skill-council" onClick={closeMobileMenu}>For Sector Skill Council</Link></li>
                  <li><Link className={location.pathname === '/individuals' ? 'active' : ''} to="/individuals" onClick={closeMobileMenu}>For Individuals</Link></li>
                  <li><Link className={location.pathname === '/assessments' ? 'active' : ''} to="/assessments" onClick={closeMobileMenu}>Type of Assessments</Link></li>
                </ul>
              </li>

              {/* Events Dropdown */}
              {/* <li className={`dropdown ${eventsDropdownOpen ? 'active' : ''}`} onClick={toggleEventsDropdown}>
                <Link to="#" className={eventsDropdownOpen || location.pathname.startsWith('/events') ? 'active' : ''}>
                  <span>Events & Media</span> <i className="bi bi-chevron-down"></i>
                </Link>
                <ul className={`dropdown-ul ${eventsDropdownOpen ? 'dropdown-active' : ''}`}>
                  <li><Link className={location.pathname === '/events' ? 'active' : ''} to="/events" onClick={closeMobileMenu}>Events</Link></li>
                  <li><Link className={location.pathname === '/gallery' ? 'active' : ''} to="/gallery" onClick={closeMobileMenu}>Gallery</Link></li>
                  <li><Link className={location.pathname === '/videos' ? 'active' : ''} to="/videos" onClick={closeMobileMenu}>Videos</Link></li>
                  <li><Link className={location.pathname === '/blog' ? 'active' : ''} to="/blog" onClick={closeMobileMenu}>Blog</Link></li>
                </ul>
              </li> */}
              <li><Link className={location.pathname === '/contact' ? 'active' : ''} to="/contact" onClick={closeMobileMenu}>Contact Us</Link></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" id='closebtn' onClick={toggleMobileMenu}></i>
          </nav>
          <Link to="request-demo" className="get-started-btn" onClick={closeMobileMenu}>Request Demo</Link>
        </div>
      </header>
      {/* <!-- End Header --> */}
    </>
  );
}

export default Header;
