import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from './Component/Header'
import Footer from './Component/Footer'
import Home from './Component/Home'
import About from './Component/About'
import MyGallery from './Component/MyGallery'
import VideoGallery from './Component/VideoGallery'
import EventPage from './Component/EventPage'
import EventDetails from './Component/EventDetails'
import Page404 from './Component/Page404'
import Blog from './Component/Blog'
import Contact from './Component/Contact'
import Universities from './Component/Universities'
import Schools from './Component/Schools'
import Corporates from './Component/Corporates'
import SectorSkillCouncil from './Component/SectorSkillCouncil'
import Individuals from './Component/Individuals'
import Assessments from './Component/Assessments'
import RequestDemo from './Component/RequestDemo'
import PrivacyPolicy from './Component/PrivacyPolicy'
import TermsConditions from './Component/TermsConditions'

function App() {
  return (
    <>
      <BrowserRouter basename="/">
      <Header/>
        <Routes>
          <Route path='/' exact element={<Home/>}/>
          <Route path='about' element={<About/>}/>
          <Route path='gallery' element={<MyGallery/>}/>
          <Route path='videos' element={<VideoGallery/>} />
          <Route path='events' element={<EventPage/>} />
          <Route path='event-details' element={<EventDetails/>} />
          <Route path='blog' element={<Blog/>} />
          <Route path='universities' element={<Universities/>} />
          <Route path='schools' element={<Schools/>} />
          <Route path='corporates' element={<Corporates/>} />
          <Route path='skill-council' element={<SectorSkillCouncil/>} />
          <Route path='individuals' element={<Individuals/>} />
          <Route path='assessments' element={<Assessments/>} />
          <Route path='contact' element={<Contact/>} />
          <Route path='request-demo' element={<RequestDemo/>} />
          <Route path='privacy-policy' element={<PrivacyPolicy/>} />
          <Route path='terms-conditions' element={<TermsConditions/>} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  )
}

export default App
