import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

function Page404() {
    return (
        <>
            <Helmet>    
                <title>404 Page Not Found || City Translink ||</title>
            </Helmet>
            <main id="main">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h1>404 Page Not Found</h1>
                        <Link to='/' className='btn btn-light mt-5'>Go To Home</Link>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}
            </main>
        </>
    )
}

export default Page404
