import React from 'react'
import { Link } from 'react-router-dom'

function MainHero() {
    return (
        <>
            {/* <!-- ======= Hero Section ======= --> */}
            <div className="home-slider swiper" data-aos="fade-up" data-aos-delay="100">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <section id="hero" className="d-flex justify-content-center align-items-center">
                            <div className="container position-relative" data-aos="zoom-in" data-aos-delay="100">
                                <h1>Full-Cycle Assessment <br/> and Recruitment Services</h1>
                                {/* <h2>We are team of talented designers making websites with Bootstrap</h2> */}
                                <Link to="request-demo" className="btn-get-started">Get Started</Link>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {/* <!-- End Hero --> */}
        </>
    )
}

export default MainHero
