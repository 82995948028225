import React from 'react';
// import axios from 'axios';

const ContactUsForm = () => {
    // const [formData, setFormData] = useState({
    //     name: '',
    //     email: '',
    //     subject: '',
    //     message: '',
    // });
    
    // const [submitStatus, setSubmitStatus] = useState(null);

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     try {
    //         const response = await axios.post('http://localhost:3001/contacts', formData);
    //         console.log('Data sent successfully:', response.data);
    //         setSubmitStatus('success');
    //         setFormData({ name: '', email: '', subject: '', message: '' });
            
    //     } catch (error) {
    //         console.error('Error sending data:', error);
    //         setSubmitStatus('error');
    //     }
    // };

    return (
        <>
            <form className="php-email-form">
                <div className="row">
                    <div className="col-md-6 form-group">
                        <input type="text" name="name" className="form-control" id="name"  placeholder="Your Name" required />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input type="email" className="form-control" name="email" id="email"  placeholder="Your Email" required />
                    </div>
                </div>
                <div className="form-group mt-3">
                    <input type="text" className="form-control" name="subject" id="subject"  placeholder="Subject" required />
                </div>
                <div className="form-group mt-3">
                    <textarea className="form-control" name="message"  rows="5" placeholder="Message" required ></textarea>
                </div>
                {/* <div className="my-3">
                    <div className="loading">Loading</div>
                    {submitStatus === 'success' && (
                        <div className="sent-message">Your message has been sent. Thank you!</div>
                    )}
                    {submitStatus === 'error' && (
                        <div className="error-message">Error sending your message. Please try again.</div>
                    )}
                </div> */}
                <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
        </>
    )
}

export default ContactUsForm