import React from 'react'
import { Link } from 'react-router-dom'

function Events() {
  return (
   <>
   {/* <!-- ======= Events Section ======= --> */}
    <section id="events" className="events">
      <div className="container" data-aos="fade-up">

        <div className="row justify-content-center">
          <div className="col-md-4 d-flex align-items-stretch">
            <div className="card">
              <div className="card-img">
                <img src="assets/img/for-universities.jpg" alt="universities" height={'250px'} width={'100%'} loading='lazy'  />
              </div>
              <div className="card-body">
                <h5 className="card-title"><Link to="universities">For Universities & Colleges</Link></h5>
                <p className="card-text">
                  Evaluating the employability of university graduates and conducting online assessments for entrance
                  examinations and exams.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex align-items-stretch">
            <div className="card">
              <div className="card-img">
                <img src="assets/img/school-together.jpg" alt="school" height={'250px'} width={'100%'} loading='lazy' />
              </div>
              <div className="card-body">
                <h5 className="card-title"><Link to="schools">For Schools</Link></h5>
                <p className="card-text">
                  Providing students with career guidance and administering psychometric assessments for school staff.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex align-items-stretch">
            <div className="card">
              <div className="card-img">
                <img src="assets/img/coporate-bulding.jpg" alt="coporate" height={'250px'} width={'100%'} loading='lazy'/>
              </div>
              <div className="card-body">
                <h5 className="card-title"><Link to="corporates">For Corporates</Link></h5>
                <p className="card-text">
                  Comprehensive hiring solutions from start to finish, including competency-based assessments for
                  current staff members.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex align-items-stretch">
            <div className="card">
              <div className="card-img">
                <img src="assets/img/events-2.jpg" alt="events" height={'250px'} width={'100%'} loading='lazy' />
              </div>
              <div className="card-body">
                <h5 className="card-title"><Link to="skill-council">For Sector Skill Council</Link></h5>
                <p className="card-text">
                  Assessments for PMKVY, development, and translation of content based on Qualification Packs (QPs).
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex align-items-stretch">
            <div className="card">
              <div className="card-img">
                <img src="assets/img/Individuals-image.jpg" alt="Individuals" height={'250px'} width={'100%'} loading='lazy' />
              </div>
              <div className="card-body">
                <h5 className="card-title"><Link to="individuals">For Individuals</Link></h5>
                <p className="card-text">
                  There are certification choices accessible across various industries.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    {/* <!-- End Events Section --> */}
   </>
  )
}

export default Events
