import React from 'react'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function SectorSkillCouncil() {
    return (
        <>
            <Helmet>
                <title>For Sector Skill Council || City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount />
            <main id="main">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h1>For Sector Skill Council</h1>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}

                <section className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <h5 className='fw-bold mb-2'>PMKVY Assessments</h5>
                            <p>We collaborate with the government under the Pradhan Mantri Kaushal Vikas Yojana (PMKVY) to assess vocational and skill-based programs supported by the government.</p>
                            <ul>
                                <li>We boast a network of over 1000 Sector Skill Council (SSC) certified assessors, proficient in conducting assessments spanning across 29 states and 7 union territories.</li>
                                <li>We are accredited and certified by SSCs and the government to conduct assessments for a wide range of trades, encompassing but not limited to Retail, Electronics, Logistics, Automotive, Garments, Media, Instrumentation, Gem & Jewelry, Soft Skills, Banking, Security, and Information & Communication Technology (ICT).</li>
                                <li>Assessment Solutions for both Offline and Online Modalities.</li>
                                <li>Results submitted within a three-day turnaround time (TAT).</li>
                                <li>Capture and Archive Evidence through Process-Based Methods.</li>
                                <li>Assessments Conducted on Tablet Devices.</li>
                                <li>In alignment with the government's emphasis on digital literacy, our solution fully automates the examination process—whether it's an entrance exam, midterm, or semester exams—adhering to university rules and norms.</li>
                                <li>We offer training and tailored support to university staff, empowering them to independently conduct online examinations and foster self-reliance.</li>
                                <li>Offers valuable feedback for enhancement opportunities.</li>
                            </ul>
                        </div>
                        <div className='col-md-6 text-center'>
                            <img src='assets/img/farmer-planting.jpg' className='img-fluid border-radius shadow' alt='farmer' loading='lazy' />
                        </div>
                    </div>
                    <div className='row align-items-center mt-5'>
                        <div className='col-md-6 text-center'>
                            <img src='assets/img/farmer-rice.jpg' className='img-fluid border-radius shadow mb-4' style={{ width: '80%' }} alt='rice' loading='lazy' />
                        </div>
                        <div className='col-md-6'>
                            <h5 className='fw-bold mb-2'>QP based content development & translation</h5>
                            {/* <p>City Translink also offers ongoing monitoring of employee performance after hiring.</p> */}
                            <ul>
                                <li>We specialize in crafting training content tailored to diverse job roles across various sectors.</li>
                                <li>Our team of Subject Matter Experts (SME) creates content aligned with the National Skill Qualification Framework (NSQF). Some of the materials developed in accordance with these guidelines include Participant Handbook, Assessment Guide, Trainer’s Guide, Model Curriculum, PowerPoint Presentations, and Question Bank.</li>
                                <li>We additionally facilitate the translation of current content from English into multiple languages, including Hindi, Tamil, Bengali, Telugu, Kannada, Malayalam, Gujarati, Punjabi, and Marathi.</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default SectorSkillCouncil
