import React from 'react'
import { Link } from 'react-router-dom'

function Apprenticeship() {
    return (
        <>
            {/* <!-- ======= apprenticeship Section ======= --> */}
            <section id="why-us" className="why-us">
                <div className="container" data-aos="fade-up">

                    <div className="row">

                        <div className="col-lg-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-boxes d-flex flex-column justify-content-center shadow-lg">
                                <img src="./assets/img/course-details-tab-5.png" alt="detail" className="img-fluid" loading='lazy'/>
                            </div>
                        </div>
                        <div className="col-lg-8 d-flex align-items-stretch">
                            <div className="content new-padding">
                                <h3>Apprenticeship and Manpower Support</h3>
                                <p>
                                    For apprenticeship and hiring assistance, City Translink has established partnerships with over 100 leading
                                    corporations and industries. Some notable collaborators include Ultratech Cement, Bajaj, Keventers,
                                    Daikin, Luminous Inverters, Kohinoor, Supreme, Exide, and numerous others.
                                </p>
                                <div>
                                    <Link to="about" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* <!-- End apprenticeship Section --> */}

        </>
    )
}

export default Apprenticeship
