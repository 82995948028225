import React from 'react'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function Corporates() {
    return (
        <>
            <Helmet>    
                <title>For Corporates || City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount/>
            <main id="main">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h1>For Corporates</h1>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}

                <section className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <h5 className='fw-bold mb-2'>End To End Hiring Solution</h5>
                            <p>Addressing the diverse needs of organizations seeking swift and efficient candidate recruitment, City Translink offers an ideal solution to streamline the hiring process. City Translink presents a screening system designed to secure top-quality candidates while minimizing the time and effort involved in the hiring process.</p>
                            <ul>
                                <li>Development of a platform for candidates to submit their applications.</li>
                                <li>Matching candidates with job descriptions.</li>
                                <li>Reviewing applications and resumes according to predefined criteria.</li>
                                <li>Evaluating candidates to pinpoint the top contenders.</li>
                                <ul className='list_style_icon'>
                                    <li>
                                        <p>
                                            <span><i className="bi bi-check"></i> Aptitude tests</span>
                                            <span><i className="bi bi-check"></i> Analytical tests</span>
                                            <span><i className="bi bi-check"></i> Psychometric tests</span>
                                            <span><i className="bi bi-check"></i> Industry knowledge tests</span>
                                        </p>
                                    </li>
                                </ul>
                                <li>Conducting interviews or discussions with candidates to narrow down the shortlist.</li>
                                <ul className='list_style_icon'>
                                    <li>
                                        <p>
                                            <span><i className="bi bi-check"></i> Telephonic</span>
                                            <span><i className="bi bi-check"></i> Video</span>
                                            <span><i className="bi bi-check"></i> Face to face</span>
                                        </p>
                                    </li>
                                </ul>
                                <li>Verification of candidates' credentials or background checks.</li>
                                <ul className='list_style_icon'>
                                    <li><i className="bi bi-check"></i> Verification of academic qualifications through background checks.</li>
                                    <li>Verification of employment history, analysis of social media profiles, criminal background check, and credit check.</li>
                                    <li>Aadhar based authentication</li>
                                    <li>Physical verification (Home, office etc.)</li>
                                </ul>
                            </ul>
                        </div>
                        <div className='col-md-6 text-center'>
                            <img src='assets/img/coporate-bulding.jpg' className='img-fluid border-radius shadow' style={{width: '80%'}} alt='coporate' loading='lazy' />
                        </div>
                    </div>
                    <div className='row align-items-center mt-5'>
                        <div className='col-md-6 text-center'>
                            <img src='assets/img/competency-assessment.jpg' className='img-fluid border-radius shadow mb-4' style={{width: '80%'}} alt='assessment' loading='lazy' />
                        </div>
                        <div className='col-md-6'>
                            <h5 className='fw-bold mb-2'>Competency Based Assessment For Existing Staff</h5>
                            <p>City Translink also offers ongoing monitoring of employee performance after hiring.</p>
                            <ul>
                                <li>Continuous assessments</li>
                                <li>Mystery shopping</li>
                            </ul>
                            <p>Continuous skill enhancement for employees to align with the evolving industry requirements.</p>
                            <ul>
                                <li>Online learning</li>
                                <li>Class room learning</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Corporates
