import React from 'react'
import { Link } from 'react-router-dom'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function EventDetails() {
    return (
        <>
            <Helmet>
                <title>Event Details || City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount />
            <main id="main">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h1>Event Details</h1>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}

                <section id="course-details" className="course-details">
                    <div className="container" data-aos="fade-up">

                        <div className="row">
                            <div className="col-lg-8">
                                <img src="assets/img/events-1.jpg" className="img-fluid" alt="" loading='lazy' />
                                <h3>Introduction to webdesign</h3>
                                <p>
                                    Qui et explicabo voluptatem et ab qui vero et voluptas. Sint voluptates temporibus quam autem. Atque nostrum voluptatum laudantium a doloremque enim et ut dicta. Nostrum ducimus est iure minima totam doloribus nisi ullam deserunt. Corporis aut officiis sit nihil est. Labore aut sapiente aperiam.
                                    Qui voluptas qui vero ipsum ea voluptatem. Omnis et est. Voluptatem officia voluptatem adipisci et iusto provident doloremque consequatur. Quia et porro est. Et qui corrupti laudantium ipsa.
                                    Eum quasi saepe aperiam qui delectus quaerat in. Vitae mollitia ipsa quam. Ipsa aut qui numquam eum iste est dolorum. Rem voluptas ut sit ut.
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <div className="course-info d-flex justify-content-between align-items-center">
                                    <h5>Trainer</h5>
                                    <p><Link to="#">Walter White</Link></p>
                                </div>
                                <div className="course-info d-flex justify-content-between align-items-center">
                                    <h5>Available Seats</h5>
                                    <p>30</p>
                                </div>
                                <div className="course-info d-flex justify-content-between align-items-center">
                                    <h5>Schedule</h5>
                                    <p>Sunday, November 15th</p>
                                </div>
                                <div className="course-info d-flex justify-content-between align-items-center">
                                    <h5>Time</h5>
                                    <p>7:00 pm</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    )
}

export default EventDetails
