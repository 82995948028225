import React from 'react'
import { Link } from 'react-router-dom'

function VisionMission() {
    return (
        <>
            {/* <!-- ======= Vision & Mission Section ======= --> */}
            <section id="why-us" className="why-us">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-12 d-flex align-items-stretch">
                            <div className="content new-padding">
                                <div className="row">
                                    <h3 className='text-center mb-5'>Vision & Mission</h3>
                                    <div className="col-md-6 mb-4">
                                        <h4><img src='assets/img/Icons/vission.png' alt='icon' className='me-2' height={'40px'} loading='lazy' /> Vision</h4>
                                        <p>
                                            To establish City Translink as a premier assessment entity in the ever-evolving
                                            landscape of diverse skill assessments and corporate evaluations, becoming a
                                            fundamental contributor to the ongoing skilling revolution.
                                        </p>
                                        <div>
                                            <Link to="#" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <h4><img src='assets/img/Icons/mission.png' alt='icon' className='me-2' height={'40px'} loading='lazy' /> Mission</h4>
                                        <p>
                                            To enhance fairness and excellence within the Skill Development ecosystem, we aim to conduct research-based, 
                                            valid, reliable, efficient, transparent, fair, and internationally benchmarked assessments.
                                        </p>
                                        <div>
                                            <Link to="#" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End apprenticeship Section --> */}
        </>
    )
}

export default VisionMission
