import React, { useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Modal } from 'react-bootstrap';
import ScrollToTopOnMount from './ScrollToTopOnMount';
import YouTube from 'react-youtube';
import { Helmet } from 'react-helmet';

const videos = [
  {
    id: 1,
    type: 'youtube',
    youtubeId: '22bLNq6iCjU?si=XtZrCVXnCT7b8W11',
    description: 'YouTube Video 1',
  },
  {
    id: 2,
    type: 'local',
    source: 'assets/videos/trailer.mp4',
    thumbnail: 'assets/img/thumbnail/thumb-1.png',
    description: 'Local Video 1',
  },
  {
    id: 3,
    type: 'youtube',
    youtubeId: '7DoPV6jt_ng?si=1zLb8ciC45gZUhef',
    description: 'YouTube Video 2',
  },
  {
    id: 4,
    type: 'local',
    source: 'assets/videos/trailer.mp4',
    thumbnail: 'assets/img/thumbnail/thumb-1.png',
    description: 'Local Video 2',
  },
];

const VideoGallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);

  const openVideo = (index) => {
    setSelectedVideoIndex(index);
    setShowModal(true);
  };

  const closeVideo = () => {
    setSelectedVideoIndex(0);
    setShowModal(false);
  };

  const opts = {
    height: '290px',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <>
      <Helmet>
        <title>Our Video Gallery || City Translink ||</title>
      </Helmet>
      <ScrollToTopOnMount />
      <main id="main">
        {/* Breadcrumbs */}
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h1>Our Video Gallery</h1>
          </div>
        </div>
        {/* End Breadcrumbs */}

        <div className="container mt-5">
          <div className="row">
            {videos.map((video, index) => (
              <div key={index} className="col-md-6 col-lg-4 mb-4">
                {video.type === 'youtube' ? (
                  <div className="img-fluid"
                    style={{ cursor: 'pointer', position: 'relative', paddingBottom: '0', overflow: 'hidden' }}
                    onClick={() => openVideo(index)}>
                    <YouTube videoId={video.youtubeId} opts={opts} />
                  </div>
                ) : (
                  <div>
                    <video
                      src={video.source}
                      poster={video.thumbnail} // Use the video thumbnail as the poster
                      alt={video.description}
                      className="img-fluid"
                      style={{ cursor: 'pointer', height: '300px' }}
                      onClick={() => openVideo(index)}
                    />
                  </div>
                )}
                <p className="mt-2">{video.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Modal */}
        {showModal && (
          <Modal show={showModal} onHide={closeVideo} centered size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Video Gallery</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {videos[selectedVideoIndex].type === 'youtube' ? (
                <YouTube videoId={videos[selectedVideoIndex].youtubeId} opts={opts} />
              ) : (
                <video src={videos[selectedVideoIndex].source} controls width="100%" height="300px" />
              )}
            </Modal.Body>
          </Modal>
        )}
      </main>
    </>
  );
};

export default VideoGallery;
