import React from 'react'
import { Link } from 'react-router-dom'

function Certification() {
  return (
    <>
    {/* <!-- your certification Section --> */}
    <section id="certification" className="certification">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-md-6 content">
            <div className="icon-box mt-4 mt-xl-0">
              <i className="bx bx-receipt"></i>
              <h4 className="mb-4">Choose your certification</h4>
              <ul>
                <li><i className="bi bi-circle"></i> University certificate</li>
                <li><i className="bi bi-circle"></i> Industry certificate</li>
                <li><i className="bi bi-circle"></i> Sector skill certificate</li>
                <li><i className="bi bi-circle"></i> CTAT certification</li>
              </ul>
              <Link to="about" className="learn-more-btn">Know More</Link>
            </div>
          </div>
          <div className="col-md-6 content" data-aos=" fade-right" data-aos-delay="100">
            <div className="icon-box mt-4 mt-xl-0">
              <i className="bx bx-receipt"></i>
              <h4 className="mb-4">Type of tests</h4>
              <ul>
                <li><i className="bi bi-circle"></i> Psychometric tests</li>
                <li><i className="bi bi-circle"></i> Aptitude tests</li>
                <li><i className="bi bi-circle"></i> Communication and language tests</li>
                <li><i className="bi bi-circle"></i> Industry skill based test</li>
              </ul>
              <Link to="about" className="learn-more-btn">Know More</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!-- End your certification Section --> */}
    </>
  )
}

export default Certification
