import React from 'react'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function Universities() {
    return (
        <>
            <Helmet>
                <title>For Universities || City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount/>
            <main id="main">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h1>For Universities</h1>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}

                <section className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <h5 className='fw-bold mb-4'>Assessing employability for University Graduates</h5>
                            <ul className='mb-5'>
                                <li>Provides a summary of the job market, emphasizing the key job profiles in demand.</li>
                                <li>CTAT facilitates universities in evaluating students' preparedness for the transition to the professional realm.</li>
                                <li>Offers positive and constructive criticism for development.</li>
                            </ul>
                            <h5 className='fw-bold mb-4'>Online assessment for entrance & exams</h5>
                            <ul>
                                <li>Adhering to the government's focus on digital literacy, our solution automates the examination process comprehensively, covering entrance exams, midterms, and semester exams based on the university's rules and standards.</li>
                                <li>We equip university staff with training and tailored support to conduct online examinations independently, fostering self-sufficiency.</li>
                                <li>Our solution can generate customized reports and securely store records on the server for convenient future reference.</li>
                            </ul>
                        </div>
                        <div className='col-md-6'>
                            <img src='assets/img/universities.jpg' className='img-fluid border-radius shadow' alt='university' loading='lazy' />
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Universities
